/* .loaded_hiding  */
.preload-items {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(5px);
}
.preload-items p {
  display: inline-block;
  font-size: 30px;
  padding: 0 15px;
  color: #3e3e3e;
}
.items-1 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: -0.5s;
}
.items-2 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 0.5s;
}
.items-3 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 1s;
}
.items-4 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 1.5s;
}
.items-5 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 2s;
}
.items-6,
.items-7,
.items-8 {
  display: inline-block;
  background-color: #3e3e3e;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 5px;
}
.items-6 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 2.5s;
}
.items-7 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 3s;
}
.items-8 {
  animation: load 2.5s ease-in-out infinite;
  animation-delay: 3.5s;
}
@keyframes load {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(35px);
  }
}
