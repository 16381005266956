.edit-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1001;
  overflow: hidden;
  overflow-y: scroll;
}
.edit-open-modal.active {
  opacity: 1;
  pointer-events: all;
}

.edit-modal-content {
  position: absolute;
  top: 0;
  right: -100vw;
  min-height: 100vh;
  width: 800px;
  padding: 1.25rem;
  transition: 0.4s all;
  z-index: 1002;
  background: var(--color-white);
}

.edit-modal-content.activee {
  right: 0;
}

.edit-open-modal::-webkit-scrollbar {
  display: none;
}

/* Скрываем scrollbar для IE, Edge и Firefox */
.edit-open-modal {
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
}

body.edit-open-modal {
  height: 100vh;
  overflow-y: hidden;
}

@media (min-width: 220px) and (max-width: 1175px) {
  .edit-open-modal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 80px 0;
  }
  .edit-modal-content {
    position: static;
    top: 80px;
    right: 0;
    left: 0;
    border-radius: 0.625rem;
  }
  .edit-modal-content.activee {
    width: calc(100vw - 250px) !important;
  }
}
