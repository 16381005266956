/* listing wrapper */
.ListingHeader {
  padding: 0.625rem 0;
}
/*listing card*/
.listingContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(365px, 1fr));
  gap: var(--margin-10);
}
.listingContainerOne {
  display: grid;
  grid-template: auto / repeat(auto-fit, minmax(365px, 1fr));
  gap: var(--padding-5);
}
.listingImgBlockStyle {
  width: 100%;
  height: 250px;
}
.listingTable {
  width: 100%;
  margin-top: 0.625rem;
}
.listingTableTd {
  vertical-align: top;
}
.listingTableTdConveniences {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  /* width: 50%; */
}
.listingTextDescription {
  text-align: justify;
  /* min-width: 450px; */
  max-height: 100px;
  overflow: hidden;
  overflow-y: scroll;
}
/* block listing add and block leaflet */
.blockLeaflet {
  width: 100%;
  height: 21.875rem !important;
}
.containerLafleatImg {
  background-color: var(--color-f6f6f6);
}
.LafleatHeaderImg {
  display: flex;
  justify-content: flex-end;
  padding: var(--margin-10);
}
.blockLafleatImg {
  display: flex;
  gap: var(--margin-10);
}
.cardLeafletImg {
  width: 100%;
  height: 15.625rem !important;
  padding: var(--margin-10);
}
.blockLeafletImgItems {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
