.card-wrapper {
  min-width: 33%;
  box-shadow: var(--box-shadow-2);
  border-radius: var(--border-radius-5px);
  background-color: var(--color-white);
  padding: var(--padding-15);
}
.card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--padding-15);
  align-items: center;
  column-gap: 10px;
}
.header-title {
  margin: 0;
  font-size: 1.125rem;
  height: 54px;
  overflow: hidden;
}
.header-btn-block {
  display: flex;
  gap: 10px;
}

.card-body {
  /* user-select: none; */
  /* overflow: hidden; */
}
